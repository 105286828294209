"use client";

import Image from "next/image";
import Link from "next/link";
import { useTheme } from "next-themes";

import { useSidebar } from "@/shared/hooks/use-sidebar";
import { useStore } from "@/shared/hooks/use-store";
import { Button } from "@/shared/libs/shadcn/components/ui/button";
import { cn } from "@/shared/libs/shadcn/utils";
import { SidebarMenu } from "./SidebarMenu.ui";
import { SidebarToggle } from "./SidebarToggle.ui";
import brandLogoDark from "@/public/logo/sloth_dark.svg";
import brandLogoLight from "@/public/logo/sloth_light.svg";

export const Sidebar = () => {
  const sidebar = useStore(useSidebar, (x) => x);
  const { theme } = useTheme();

  if (!sidebar) return null;
  const { isOpen, toggleOpen, getOpenState, setIsHover, settings } = sidebar;

  return (
    <aside
      className={cn(
        "fixed top-0 left-0 z-20 h-screen -translate-x-full lg:translate-x-0 transition-[width] ease-in-out duration-300",
        !getOpenState() ? "w-[90px]" : "w-72",
        settings.disabled && "hidden"
      )}
    >
      <SidebarToggle isOpen={isOpen} setIsOpen={toggleOpen} />
      <div
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
        className="relative h-full flex flex-col px-3 py-4 overflow-y-auto shadow-md dark:shadow-zinc-800"
      >
        <Button
          className={cn("transition-transform ease-in-out duration-300 mb-1", !getOpenState() ? "translate-x-1" : "translate-x-0")}
          variant="link"
          asChild
        >
          <Link href="/dashboard" className="flex items-center gap-2">
            <Image src={theme === "dark" ? brandLogoDark : brandLogoLight} alt="Brand Logo" width={40} height={40} />
            <h1
              className={cn(
                "font-bold text-lg whitespace-nowrap transition-[transform,opacity,display] ease-in-out duration-300",
                !getOpenState() ? "-translate-x-96 opacity-0 hidden" : "translate-x-0 opacity-100"
              )}
            >
              Cypher
            </h1>
          </Link>
        </Button>
        <SidebarMenu isOpen={getOpenState()} />
      </div>
    </aside>
  );
};
