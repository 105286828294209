import { Coins, LayoutGrid, LucideIcon, Settings, Wallet } from "lucide-react";
import { createInstance } from "i18next";
import resourcesToBackend from "i18next-resources-to-backend";
import { initReactI18next } from "react-i18next/initReactI18next";
import { fallbackLang, defaultNS } from "@/shared/libs/i18n/i18n.constant";

type Submenu = {
  href: string;
  label: string;
  active?: boolean;
};

type Menu = {
  href: string;
  label: string;
  active?: boolean;
  icon: LucideIcon;
  submenus?: Submenu[];
};

export type Group = {
  groupLabel: string;
  menus: Menu[];
};

async function getTranslation(lang: string) {
  const i18nInstance = createInstance();
  await i18nInstance
    .use(initReactI18next)
    .use(resourcesToBackend((language: string, namespace: string) => import(`@/shared/libs/i18n/locales/${language}/${namespace}.json`)))
    .init({
      supportedLngs: [fallbackLang, "fr"],
      fallbackLng: fallbackLang,
      lng: lang,
      fallbackNS: defaultNS,
      defaultNS,
      ns: "sidebar",
    });

  return i18nInstance.getFixedT(lang, "sidebar");
}

export async function getMenuList(lang: string): Promise<Group[]> {
  const t = await getTranslation(lang);

  return [
    {
      groupLabel: "",
      menus: [
        {
          href: `/${lang}/dashboard`,
          label: t("sidebar_dashboard"),
          icon: LayoutGrid,
          submenus: [],
        },
        {
          href: `/${lang}/wallets`,
          label: t("sidebar_wallets"),
          icon: Wallet,
          submenus: [],
        },
        {
          href: `/${lang}/tokens`,
          label: t("sidebar_tokens"),
          icon: Coins,
          submenus: [],
        },
      ],
    },
    // {
    //   groupLabel: "Contents",
    //   menus: [
    //     {
    //       href: "",
    //       label: "Posts",
    //       icon: SquarePen,
    //       submenus: [
    //         {
    //           href: "/posts",
    //           label: "All Posts"
    //         },
    //         {
    //           href: "/posts/new",
    //           label: "New Post"
    //         }
    //       ]
    //     },
    //     {
    //       href: "/categories",
    //       label: "Categories",
    //       icon: Bookmark
    //     },
    //     {
    //       href: "/tags",
    //       label: "Tags",
    //       icon: Tag
    //     }
    //   ]
    // },
    {
      groupLabel: "Settings",
      menus: [
        // {
        //   href: "/users",
        //   label: "Users",
        //   icon: Users
        // },
        {
          href: `/${lang}/profile`,
          label: t("sidebar_profile"),
          icon: Settings,
        },
      ],
    },
  ];
}
