"use client";

import { Ellipsis, LogOut } from "lucide-react";
import Link from "next/link";
import { useParams, usePathname, useRouter } from "next/navigation";
import { useEffect, useState } from "react";

import { Button } from "@/shared/libs/shadcn/components/ui/button";
import { ScrollArea } from "@/shared/libs/shadcn/components/ui/scroll-area";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/shared/libs/shadcn/components/ui/tooltip";
import { cn } from "@/shared/libs/shadcn/utils";
import { CollapseSidebarButton } from "./CollapseSidebarButton.ui";
import { getMenuList, Group } from "./Sidebar.lib";
import { useTranslation as useClientTranslation } from "react-i18next";

interface MenuProps {
  isOpen: boolean | undefined;
}

export const SidebarMenu = ({ isOpen }: MenuProps) => {
  const params = useParams();
  const lang = (params?.lang as string) ?? "en";
  const pathname = usePathname();
  const [menuList, setMenuList] = useState<Group[]>([]);
  const { t } = useClientTranslation("sidebar");

  useEffect(() => {
    const loadMenuList = async () => {
      const list = await getMenuList(lang);
      setMenuList(list);
    };
    loadMenuList();
  }, [lang]);

  const router = useRouter();

  const handleLogout = async () => {
    await fetch("/api/auth/logout", { method: "POST" });
    router.push(`/${lang}/`);
  };

  return (
    <ScrollArea className="[&>div>div[style]]:!block">
      <nav className="mt-8 w-full">
        <ul className="flex flex-col min-h-[calc(100vh-48px-36px-16px-32px)] lg:min-h-[calc(100vh-32px-40px-32px)] items-start space-y-1 px-2">
          {menuList.map(({ groupLabel, menus }, index) => (
            <li className={cn("w-full", groupLabel ? "pt-5" : "")} key={index}>
              {(isOpen && groupLabel) || isOpen === undefined ? (
                <p className="text-sm font-medium text-muted-foreground px-4 pb-2 max-w-[248px] truncate">{groupLabel}</p>
              ) : !isOpen && isOpen !== undefined && groupLabel ? (
                <TooltipProvider>
                  <Tooltip delayDuration={100}>
                    <TooltipTrigger className="w-full">
                      <div className="w-full flex justify-center items-center">
                        <Ellipsis className="h-5 w-5" />
                      </div>
                    </TooltipTrigger>
                    <TooltipContent side="right">
                      <p>{groupLabel}</p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              ) : (
                <p className="pb-2"></p>
              )}
              {menus.map(({ href, label, icon: Icon, active, submenus }, index) =>
                !submenus || submenus.length === 0 ? (
                  <div className="w-full" key={index}>
                    <TooltipProvider disableHoverableContent>
                      <Tooltip delayDuration={100}>
                        <TooltipTrigger asChild>
                          <Button
                            variant={(active === undefined && pathname?.startsWith(href)) || active ? "secondary" : "ghost"}
                            className="w-full justify-start h-10 mb-1"
                            asChild
                          >
                            <Link href={href}>
                              <span className={cn(isOpen === false ? "" : "mr-4")}>
                                <Icon size={18} />
                              </span>
                              <p
                                className={cn("max-w-[200px] truncate", isOpen === false ? "-translate-x-96 opacity-0" : "translate-x-0 opacity-100")}
                              >
                                {label}
                              </p>
                            </Link>
                          </Button>
                        </TooltipTrigger>
                        {isOpen === false && <TooltipContent side="right">{label}</TooltipContent>}
                      </Tooltip>
                    </TooltipProvider>
                  </div>
                ) : (
                  <div className="w-full" key={index}>
                    <CollapseSidebarButton
                      icon={Icon}
                      label={label}
                      active={active === undefined ? pathname?.startsWith(href) ?? false : active}
                      submenus={submenus}
                      isOpen={isOpen}
                    />
                  </div>
                )
              )}
            </li>
          ))}
          <li className="w-full grow flex items-end">
            <TooltipProvider disableHoverableContent>
              <Tooltip delayDuration={100}>
                <TooltipTrigger asChild>
                  <Button onClick={handleLogout} variant="outline" className="w-full justify-center h-10 mt-5">
                    <span className={cn(isOpen === false ? "" : "mr-4")}>
                      <LogOut size={18} />
                    </span>
                    <p className={cn("whitespace-nowrap", isOpen === false ? "opacity-0 hidden" : "opacity-100")}>{t("sidebar_signout")}</p>
                  </Button>
                </TooltipTrigger>
                {isOpen === false && <TooltipContent side="right">{t("sidebar_signout")}</TooltipContent>}
              </Tooltip>
            </TooltipProvider>
          </li>
        </ul>
      </nav>
    </ScrollArea>
  );
};
