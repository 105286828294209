"use client";

import { ChevronDown, Dot, LucideIcon } from "lucide-react";
import Link from "next/link";
import { usePathname } from "next/navigation";
import { useState } from "react";

import { Button } from "@/shared/libs/shadcn/components/ui/button";
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from "@/shared/libs/shadcn/components/ui/collapsible";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/shared/libs/shadcn/components/ui/dropdown-menu";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/shared/libs/shadcn/components/ui/tooltip";
import { cn } from "@/shared/libs/shadcn/utils";
import { DropdownMenuArrow } from "@radix-ui/react-dropdown-menu";

type Submenu = {
  href: string;
  label: string;
  active?: boolean;
};

interface CollapseMenuButtonProps {
  icon: LucideIcon;
  label: string;
  active: boolean;
  submenus: Submenu[];
  isOpen: boolean | undefined;
}

export const CollapseSidebarButton = ({ icon: Icon, label, active, submenus, isOpen }: CollapseMenuButtonProps) => {
  const pathname = usePathname();
  const isSubmenuActive = submenus.some((submenu) => (submenu.active === undefined ? submenu.href === pathname : submenu.active));
  const [isCollapsed, setIsCollapsed] = useState<boolean>(isSubmenuActive);

  // Determine if the main button should be highlighted
  const isMainButtonActive = active || isSubmenuActive;

  return isOpen ? (
    <Collapsible open={isCollapsed} onOpenChange={setIsCollapsed} className="w-full">
      <CollapsibleTrigger className="[&[data-state=open]>div>div>svg]:rotate-180 mb-1" asChild>
        <Button
          variant={isMainButtonActive ? "secondary" : "ghost"}
          className={cn("w-full justify-start h-10", isMainButtonActive && "font-semibold")}
        >
          <div className="w-full items-center flex justify-between">
            <div className="flex items-center">
              <span className="mr-4">
                <Icon size={18} />
              </span>
              <p className={cn("max-w-[150px] truncate", isOpen ? "translate-x-0 opacity-100" : "-translate-x-96 opacity-0")}>{label}</p>
            </div>
            <div className={cn("whitespace-nowrap", isOpen ? "translate-x-0 opacity-100" : "-translate-x-96 opacity-0")}>
              <ChevronDown size={18} className="transition-transform duration-200" />
            </div>
          </div>
        </Button>
      </CollapsibleTrigger>
      <CollapsibleContent className="overflow-hidden data-[state=closed]:animate-collapsible-up data-[state=open]:animate-collapsible-down">
        {submenus.map(({ href, label: submenuLabel, active: submenuActive }, index) => (
          <Button
            key={index}
            variant={(submenuActive === undefined && pathname === href) || submenuActive ? "secondary" : "ghost"}
            className={cn("w-full justify-start h-10 mb-1", ((submenuActive === undefined && pathname === href) || submenuActive) && "font-semibold")}
            asChild
          >
            <Link href={href}>
              <span className="mr-4 ml-2">
                <Dot size={18} />
              </span>
              <p className={cn("max-w-[170px] truncate", isOpen ? "translate-x-0 opacity-100" : "-translate-x-96 opacity-0")}>{submenuLabel}</p>
            </Link>
          </Button>
        ))}
      </CollapsibleContent>
    </Collapsible>
  ) : (
    <DropdownMenu>
      <TooltipProvider disableHoverableContent>
        <Tooltip delayDuration={100}>
          <TooltipTrigger asChild>
            <DropdownMenuTrigger asChild>
              <Button
                variant={isMainButtonActive ? "secondary" : "ghost"}
                className={cn("w-full justify-start h-10 mb-1", isMainButtonActive && "font-semibold")}
              >
                <div className="w-full items-center flex justify-between">
                  <div className="flex items-center">
                    <span className={cn(isOpen === false ? "" : "mr-4")}>
                      <Icon size={18} />
                    </span>
                    <p className={cn("max-w-[200px] truncate", isOpen === false ? "opacity-0" : "opacity-100")}>{label}</p>
                  </div>
                </div>
              </Button>
            </DropdownMenuTrigger>
          </TooltipTrigger>
          <TooltipContent side="right" align="start" alignOffset={2}>
            {label}
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
      <DropdownMenuContent side="right" sideOffset={25} align="start">
        <DropdownMenuLabel className="max-w-[190px] truncate">{label}</DropdownMenuLabel>
        <DropdownMenuSeparator />
        {submenus.map(({ href, label: submenuLabel, active: submenuActive }, index) => (
          <DropdownMenuItem key={index} asChild>
            <Link
              className={cn("cursor-pointer", ((submenuActive === undefined && pathname === href) || submenuActive) && "bg-secondary font-semibold")}
              href={href}
            >
              <p className="max-w-[180px] truncate">{submenuLabel}</p>
            </Link>
          </DropdownMenuItem>
        ))}
        <DropdownMenuArrow className="fill-border" />
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
