"use client";

import { MenuIcon } from "lucide-react";
import Image from "next/image";
import Link from "next/link";

import { Button } from "@/shared/libs/shadcn/components/ui/button";
import { Sheet, SheetContent, SheetHeader, SheetTitle, SheetTrigger } from "@/shared/libs/shadcn/components/ui/sheet";
import { SidebarMenu } from "@/widgets/nav/SidebarMenu.ui";
import brandLogoDark from "@/public/logo/sloth_dark.svg";
import brandLogoLight from "@/public/logo/sloth_light.svg";
import { useTheme } from "next-themes";

export const SheetMenu = () => {
  const { theme } = useTheme();
  return (
    <Sheet>
      <SheetTrigger className="lg:hidden" asChild>
        <Button className="h-8" variant="outline" size="icon">
          <MenuIcon size={20} />
        </Button>
      </SheetTrigger>
      <SheetContent className="sm:w-72 px-3 h-full flex flex-col" side="left">
        <SheetHeader>
          <Button className="flex justify-center items-center pb-2 pt-1" variant="link" asChild>
            <Link href="/dashboard" className="flex items-center gap-2">
              <Image src={theme === "dark" ? brandLogoDark : brandLogoLight} alt="Brand Logo" width={32} height={32} />
              <SheetTitle className="font-bold text-lg">Cypher</SheetTitle>
            </Link>
          </Button>
        </SheetHeader>
        <SidebarMenu isOpen />
      </SheetContent>
    </Sheet>
  );
};
